import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  ProductPriceFilterEntity,
  ProductSelectionFilterEntity,
  ProductShopEntities,
  ProductTypeFilterEntity,
  ProductWeightFilterEntity,
  ShopSubcategoriesProductsRequest,
  ShopSubcategoriesProductsResponse,
} from '@core/type/api';
import { ProductBrandFilterEntity, ProductCollectionFilterEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getFilterPrice = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductPriceFilterEntity>> => {
  return axiosInstance.get(
    Api.shop.PRICE_RANGE,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getFilterWeight = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductWeightFilterEntity>> => {
  return axiosInstance.get(
    Api.shop.WEIGHT_RANGE,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getFilterProductType = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductTypeFilterEntity>> => {
  return axiosInstance.get(
    Api.shop.PRODUCTS_TYPE,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getFilterCollections = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductCollectionFilterEntity>> => {
  return axiosInstance.get(
    Api.shop.COLLECTIONS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getFilterBrands = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductBrandFilterEntity>> => {
  return axiosInstance.get(Api.shop.BRANDS, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const getFilterSelection = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductSelectionFilterEntity>> => {
  return axiosInstance.get(
    Api.shop.SELECTIONS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getProducts = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductShopEntities>> => {
  return axiosInstance.get(Api.shop.PRODUCTS, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const getSubcategoriesProducts = (
  axiosInstance: AxiosInstance,
  { language, id }: ShopSubcategoriesProductsRequest & LanguageProps,
): Promise<AxiosResponse<ShopSubcategoriesProductsResponse>> => {
  return axiosInstance.get(
    `${replaceIdUrl(Api.shop.SUBCATEGORIES_PRODUCTS, id, ':id')}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class ShopAPI {
  public static readonly getFilterPrice = getFilterPrice;
  public static readonly getFilterWeight = getFilterWeight;
  public static readonly getFilterProductType = getFilterProductType;
  public static readonly getFilterCollections = getFilterCollections;
  public static readonly getFilterBrands = getFilterBrands;
  public static readonly getFilterSelection = getFilterSelection;
  public static readonly getProducts = getProducts;
  public static readonly getSubcategoriesProducts = getSubcategoriesProducts;
}
